// Press "ESC"
$(document).keyup(function (e) {
    if (e.keyCode == 27) {
        $(".header-mainnav, .bookmarks-toggle, .search-wrapper, .search-close").removeClass("search-active");
        $(".overlay").removeClass("active menu-active search-active bookmarks-active open-position-active off-canvas-active");
        $(".hamburger").removeClass("is-active");
        $("#off-canvas").removeClass("active");
        $("#off-canvas").removeClass("off-canvas-active");
        $(".filter-select-wrapper ul").removeClass("open");
        $("body").removeClass("no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas");
        $("#main-nav ul li.has-subnav").removeClass("active");
        $("#main-nav ul li").removeClass("active");
        $('.dropdown-box').removeClass('dropdown-active');
        $('.active-hover').removeClass('active-c');
    }
});
$(".overlay").click(function () {
    $(".overlay").removeClass("active open-position-active off-canvas-active");
    $(".hamburger").removeClass("is-active");
    $("#off-canvas").removeClass("active");
    $("body").removeClass("no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas");
});

// OffCanvas

$(".off-canvas-toggle").click(function () {
    $(".hamburger").toggleClass("is-active");
    $("#main-wrapper, .overlay").toggleClass("off-canvas-active");
    $("#off-canvas").toggleClass("active");
    $("body").toggleClass("no-scroll-off-canvas");
    var offCanvasHeight = $(".off-canvas-wrapper").height();
    $(".off-canvas-wrapper").css("height", offCanvasHeight);
});

$('.acc-menu > ul > li:has(ul)').addClass("has-subnav");
$('.acc-menu > ul > li > a').click(function() {
    $(this).closest('li').toggleClass('open') ;
});

//      Slider Unterseite Maschine
$('.slider-maschine').slick({
    infinite: true,
    speed: 1500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 500,
    variableWidth: true,
    focusOnSelect: true,
    prevArrow: '<button type="button" class="slick-prev"></button>',
    nextArrow: '<button type="button" class="slick-next"></button>',
    responsive: [
        {
            breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 500
                }
        }
    ]
});

$(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);
});